// Base Class Definition
// -------------------------

@mixin icons8-font() {
  display: inline-block;
  font-family: $icons8-font-name;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  direction: ltr;
  font-feature-settings: 'liga';
  font-size: 24px;

  .menu-item & {
    margin-right: 8px;
  }

  .mat-button & {
    vertical-align: middle;
  }
}
