// Icons
// -------------------------

.icon-arrow-long:before,
.icon-arrow-small:before,
.icon-calendar:before,
.icon-card:before,
.icon-chat-bubble:before,
.icon-check-mark---square:before,
.icon-check-mark:before,
.icon-check-circle-fill:before,
.icon-circle-selected:before,
.icon-clock-timer:before,
.icon-close---circle:before,
.icon-close:before,
.icon-dashboard:before,
.icon-dialogue:before,
.icon-dots:before,
.icon-duplicate:before,
.icon-edit:before,
.icon-email:before,
.icon-eye:before,
.icon-file:before,
.icon-graph:before,
.icon-group:before,
.icon-image:before,
.icon-info:before,
.icon-lock:before,
.icon-megaphone:before,
.icon-minus:before,
.icon-money:before,
.icon-move---dots:before,
.icon-multiple-choice:before,
.icon-notifications:before,
.icon-number:before,
.icon-numeric:before,
.icon-pause:before,
.icon-phone:before,
.icon-play:before,
.icon-plus:before,
.icon-privacy-policy:before,
.icon-question-circle:before,
.icon-search:before,
.icon-settings-cog:before,
.icon-single-choice:before,
.icon-slider:before,
.icon-support:before,
.icon-terms-and-conditions:before,
.icon-thunder-stroke:before,
.icon-thunder:before,
.icon-trash:before,
.icon-user-stroke:before,
.icon-user:before,
.icon-wallet-fill:before,
.icon-zoom-in:before,
.icon-circle:before,
.icon-square:before,
.icon-cog-stoke:before,
.icon-send:before,
.icon-image-choice:before,
.icon-open-ended:before,
.icon-reorder-items:before,
.icon-star-rating:before,
[data-icons8]:before {
  @include icons8-font;
}

[data-icons8]:before {
  content: attr(data-icons8);
}

.icon-arrow-long:before {
  content: $icons8-var-arrow-long;
}
.icon-arrow-small:before {
  content: $icons8-var-arrow-small;
}
.icon-calendar:before {
  content: $icons8-var-calendar;
}
.icon-card:before {
  content: $icons8-var-card;
}
.icon-chat-bubble:before {
  content: $icons8-var-chat-bubble;
}
.icon-check-mark---square:before {
  content: $icons8-var-check-mark---square;
}
.icon-check-mark:before {
  content: $icons8-var-check-mark;
}
.icon-check-circle-fill:before {
  content: $icons8-var-check-circle-fill;
}
.icon-circle-selected:before {
  content: $icons8-var-circle-selected;
}
.icon-clock-timer:before {
  content: $icons8-var-clock-timer;
}
.icon-close---circle:before {
  content: $icons8-var-close---circle;
}
.icon-close:before {
  content: $icons8-var-close;
}
.icon-dashboard:before {
  content: $icons8-var-dashboard;
}
.icon-dialogue:before {
  content: $icons8-var-dialogue;
}
.icon-dots:before {
  content: $icons8-var-dots;
}
.icon-duplicate:before {
  content: $icons8-var-duplicate;
}
.icon-edit:before {
  content: $icons8-var-edit;
}
.icon-email:before {
  content: $icons8-var-email;
}
.icon-eye:before {
  content: $icons8-var-eye;
}
.icon-file:before {
  content: $icons8-var-file;
}
.icon-graph:before {
  content: $icons8-var-graph;
}
.icon-group:before {
  content: $icons8-var-group;
}
.icon-image:before {
  content: $icons8-var-image;
}
.icon-info:before {
  content: $icons8-var-info;
}
.icon-lock:before {
  content: $icons8-var-lock;
}
.icon-megaphone:before {
  content: $icons8-var-megaphone;
}
.icon-minus:before {
  content: $icons8-var-minus;
}
.icon-money:before {
  content: $icons8-var-money;
}
.icon-move---dots:before {
  content: $icons8-var-move---dots;
}
.icon-multiple-choice:before {
  content: $icons8-var-multiple-choice;
}
.icon-notifications:before {
  content: $icons8-var-notifications;
}
.icon-number:before {
  content: $icons8-var-number;
}
.icon-numeric:before {
  content: $icons8-var-numeric;
}
.icon-pause:before {
  content: $icons8-var-pause;
}
.icon-phone:before {
  content: $icons8-var-phone;
}
.icon-play:before {
  content: $icons8-var-play;
}
.icon-plus:before {
  content: $icons8-var-plus;
}
.icon-privacy-policy:before {
  content: $icons8-var-privacy-policy;
}
.icon-question-circle:before {
  content: $icons8-var-question-circle;
}
.icon-search:before {
  content: $icons8-var-search;
}
.icon-settings-cog:before {
  content: $icons8-var-settings-cog;
}
.icon-single-choice:before {
  content: $icons8-var-single-choice;
}
.icon-slider:before {
  content: $icons8-var-slider;
}
.icon-support:before {
  content: $icons8-var-support;
}
.icon-terms-and-conditions:before {
  content: $icons8-var-terms-and-conditions;
}
.icon-thunder-stroke:before {
  content: $icons8-var-thunder-stroke;
}
.icon-thunder:before {
  content: $icons8-var-thunder;
}
.icon-trash:before {
  content: $icons8-var-trash;
}
.icon-user-stroke:before {
  content: $icons8-var-user-stroke;
}
.icon-user:before {
  content: $icons8-var-user;
}
.icon-wallet-fill:before {
  content: $icons8-var-wallet-fill;
}
.icon-zoom-in:before {
  content: $icons8-var-zoom-in;
}
.icon-circle:before {
  content: $icons8-var-circle;
}
.icon-square:before {
  content: $icons8-var-square;
}
.icon-cog-stoke:before {
  content: $icons8-var-cog-stoke;
}
.icon-send:before {
  content: $icons8-var-send;
}
.icon-image-choice:before {
  content: $icons8-var-imagechoice;
}
.icon-open-ended:before {
  content: $icons8-var-openended;
}
.icon-reorder-items:before {
  content: $icons8-var-reorderitems;
}
.icon-star-rating:before {
  content: $icons8-var-starrating;
}
