@import 'colors';

// //////////////////////////////////////////////////////////////////////////////
// typography and stuff

h1,
h2,
h3,
h4,
h5,
h6 {
  color: map-get($black-palette, 100);
}

// //////////////////////////////////////////////////////////////////////////////
// side bar

app-sidenav {
  padding: 0;
  height: 100%;

  & nav {
    max-width: 250px;
    height: 100%;
    padding: 10px;
    background: white;
    border-radius: 0;
    border-right: 1px solid map-get($white-palette, 60);
  }
}

.menu-item {
  background-color: white;

  & .icon {
    margin-right: 8px;
  }
}

.menu-button {
  &.active {
    background-color: map-get($primary-palette, 5) !important;
    color: map-get($primary-palette, 100);
  }
}

// //////////////////////////////////////////////////////////////////////////////
// tool bar

.toolbar {
  border: 1px solid map-get($white-palette, 60);
}

// //////////////////////////////////////////////////////////////////////////////
// controls and inputs

input,
textarea,
select {
  border: 1px solid map-get($black-palette, 50);
  border-radius: var(--border-radius);

  &:focus {
    border: 1px solid map-get($black-palette, 90);
  }

  &:disabled {
    background-color: map-get($white-palette, 90);
    color: map-get($black-palette, 60);
  }
}

ng-select {
  & .ng-select-container {
    border: 1px solid map-get($black-palette, 50);
  }

  &.ng-select-opened {
    border-radius: var(--border-radius) var(--border-radius) 0 0;
    & .ng-select-container {
      border-radius: var(--border-radius) var(--border-radius) 0 0;
    }
  }
}

.ng-dropdown-panel {
  border-radius: 0 0 var(--border-radius) var(--border-radius) !important;
  box-shadow: none;
  border: 1px solid map-get($black-palette, 50);
  margin-top: -2px !important;

  & .ng-option {
    border-top: 1px solid map-get($black-palette, 50);
    &:first-child {
      border-top: 0;
    }
    &:last-child {
      border-radius: 0 0 var(--border-radius) var(--border-radius) !important;
    }
  }
}

mat-checkbox {
  & [matripple] {
    display: none;
  }
  & .mat-checkbox-background,
  & .mat-checkbox-frame {
    border-radius: 4px;
  }
  &.mat-checkbox-checked .mat-checkbox-background {
    background-color: map-get($primary-palette, 100) !important;
  }
}

// //////////////////////////////////////////////////////////////////////////////
// tables

app-filters {
  margin-bottom: 0;

  & header {
    background: white;
    padding: 1rem;
    border-radius: var(--border-radius) var(--border-radius) 0 0;
  }
}

section.table-wrapper {
  & > *:last-child {
    border-radius: 0 0 var(--border-radius) var(--border-radius);
  }
}

.mat-table {
  & thead tr {
    & th {
      border: 1px solid map-get($white-palette, 50);
      border-bottom: none;
      background-color: map-get($white-palette, 90);

      &:first-child {
        border-right: none;
      }
      &:not(:first-child):not(:last-child) {
        border-right: none;
        border-left: none;
      }
      &:last-child {
        border-left: none;
      }
    }
  }

  & tbody {
    background-color: white;

    &:hover {
      cursor: pointer;
    }

    & tr:not(.empty-table-row) {
      &:hover {
        background-color: map-get($white-palette, 90);
        &:first-child td {
          border-top: 1px solid map-get($white-palette, 60) !important;
        }
      }
      & td {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
        border-top: 1px solid transparent;
        border-bottom: 1px solid map-get($white-palette, 60) !important;
        &:first-child {
          border-left: 1px solid map-get($white-palette, 60) !important;
        }
        &:last-child {
          border-right: 1px solid map-get($white-palette, 60) !important;
        }
      }
    }
  }
}

.mat-menu-panel {
  & button {
    transition: var(--transition);
  }
  background-color: map-get($black-palette, 90);
  border-radius: var(--border-radius) !important;

  & .mat-menu-content {
    padding: 0 !important;
    & .mat-menu-item {
      color: map-get($white-palette, 60) !important;
      &:not(:last-child) {
        border-bottom: 1px solid map-get($black-palette, 80);
      }
      &:hover {
        background-color: map-get($black-palette, 100);
      }
    }
  }
}

.card {
  background-color: white;
  box-shadow: none;
  border-radius: var(--border-radius);
}

.page-header {
}
