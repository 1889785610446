$primary-palette: (
  2: #f5fefd,
  5: #f2f9f8,
  10: #d4edeb,
  20: #bfe4e1,
  30: #a9dbd7,
  50: #8adcd4,
  100: #28a69a,
  120: #18645c,
  170: #08211f,
);

$blue-palette: (
  20: #f1f1ff,
  30: #eceffe,
  100: #677cec,
  130: #273aa9,
);

$black-palette: (
  100: #131314,
  90: #393a3c,
  80: #5f6264,
  70: #84898b,
  60: #aab0b3,
  50: #bdc3c7,
);

$white-palette: (
  100: #fff,
  95: #fbfbfc,
  90: #f8f9f9,
  80: #f2f3f4,
  70: #ebedee,
  60: #e5e7e9,
  50: #d7dbdd,
);

$yellow-palette: (
  130: #c99d2b,
  100: #f6d16f,
  30: #fdf0d0,
  20: #fefaf0,
);

$red-palette: (
  100: #f26666,
  30: #fad5d5,
  10: #fff2f2,
  5: #fffbfb,
);

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@500;600;700&display=swap');
// Global variables
:root {
  --font-family: 'Open Sans', sans-serif; // font included above

  --color-primary: #{map-get($primary-palette, 100)};
  --color-secondary: #{map-get($primary-palette, 5)};
  --color-primary-hover: #6b7cd2;
  --color-primary-active: #2e3d9b;
  --color-primary-focus: var(--color-primary-active);
  --color-accent: #a97838;
  --color-warn: #f44336;
  --color-info: #50a5f1;
  --color-success: #54b676;
  --color-border: rgba(0, 0, 0, 0.2);

  --color-table-header: #{map-get($white-palette, 90)};
  --color-table-lines: #{map-get($white-palette, 50)};

  --color-primary-text: #{map-get($black-palette, 80)};
  --color-text: #{map-get($black-palette, 80)};
  --color-text-fade: hsl(0, 0%, 60%);
  --color-icon-input: #5c5c5c;

  --color-background: #f2f3f4;
  --color-background-2: white;
  --color-background-2-hover: hsl(0, 0%, 90%);
  --color-background-accent: #222b45;
  --color-background-accent-hover: #373f58;

  --shadow: 0px 1px 15px 1px rgba(65, 65, 65, 0.1);
  --shadow-menu: 0px 4px 20px 1px rgba(65, 65, 65, 0.15), 0px 4px 8px -3px rgba(65, 65, 65, 0.3);
  --border-radius: 9px;
  --transition: all 150ms ease;
  --transition-slow: all 300ms ease;

  --height-input: 42px;
  --height-toolbar: 64px;
}
