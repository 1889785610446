@use '@angular/cdk';
@include cdk.text-field-autofill();
@import 'include-media';

html {
  height: 100%;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  height: 100%;
  font-family: var(--font-family), sans-serif;
  background-color: var(--color-background);
  color: var(--color-text);
}

h1 {
  font-size: 2rem;
  margin: 0 0 1.5rem;
}

h2 {
  font-size: 1.7rem;
  margin: 0 0 1rem;
}

input {
  line-height: 1;
  height: var(--height-input);
  padding: 0 1rem;
}

textarea {
  padding: 0.7rem 1rem;
  resize: vertical;
  min-height: var(--height-input);
}

select {
  padding: 0 1rem;
  height: var(--height-input);
}

hr {
  width: 100%;
  border: none;
  border-bottom: 1px solid var(--color-border);
}

input,
textarea,
select {
  font-size: 14px;
  font-family: var(--font-family);
  transition: border-color 150ms ease, border-radius 150ms ease, background-color 150ms ease;

  background-color: var(--color-background-2);
  color: var(--color-text);
  border-radius: var(--border-radius);
  width: 100%;

  border: 1px solid transparent;
  // border: 1px solid hsla(0, 0%, 0%, 0.1);
  // border-top-color: transparent;
  // border-left-color: transparent;
  // border-right-color: transparent;

  @include cdk.text-field-autofill-color(transparent, var(--color-text));

  &::placeholder {
    color: var(--color-text-fade);
  }

  &:focus {
    border: 1px solid var(--color-primary);
    outline: none;
    border-radius: var(--border-radius);
  }
}

.ng-touched.ng-invalid:not(form) {
  border-radius: var(--border-radius);
  border-color: var(--color-warn);

  .ng-select-container {
    border-color: var(--color-warn);
  }

  &:hover,
  &:focus {
    border-color: var(--color-warn);
  }
}

.ng-valid + app-input-error,
.ng-untouched + app-input-error {
  display: none;
}

p {
  margin: 0 0 1rem;
}

// ////////////////////////////////////////////////////////////////

/* Material */

button.mat-button,
button.mat-flat-button,
button.mat-raised-button {
  line-height: var(--height-input);
  min-width: 100px;
  // font-size: 13px;
}

.mat-simple-snackbar-action button.mat-button {
  line-height: unset;
}

.mat-card {
  padding: 1.5rem;
  background-color: var(--color-background);

  &:not([class*='mat-elevation-z']) {
    box-shadow: var(--shadow);
  }

  @include media('<desktop') {
    padding: 0.5rem;
  }
}

.mat-tooltip {
  font-size: 12px;
  background-color: var(--color-background-accent);
  padding: 8px 15px !important;
  font-weight: 600;
}

.mat-table {
  background: transparent;
  width: 100%;

  th.mat-header-cell,
  td.mat-cell,
  td.mat-footer-cell {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-bottom-width: 0;

    &:first-of-type {
      padding-left: 20px;
    }

    &:last-of-type {
      padding-right: 20px;
    }
  }

  tr.mat-row,
  tr.mat-footer-row {
    height: 44px;
  }

  tbody tr.mat-row {
    transition: background-color 150ms ease;

    &:hover {
      background-color: var(--color-background-2);
    }
  }

  .mat-header-cell {
    color: hsl(0, 0%, 40%);
  }
}

mat-expansion-panel {
  background: transparent !important;

  &.no-styling {
    box-shadow: none !important;
    overflow: visible !important;

    &.mat-expanded .mat-expansion-panel-content:not(.ng-animating) {
      overflow: visible !important;
    }

    .mat-expansion-panel-content {
      overflow: hidden !important;
    }

    .mat-expansion-panel-body {
      padding: 0 !important;
    }
  }
}

// Other components

.ng-select {
  .ng-select-container {
    height: var(--height-input) !important;
    min-height: var(--height-input) !important;
    font-size: 14px;
    font-family: var(--font-family);
    transition: border-color 150ms ease, border-radius 150ms ease, background-color 150ms ease;
    background-color: var(--color-background-2);
    color: var(--color-text);
    border-radius: var(--border-radius);
    cursor: text;

    .ng-value-container {
      padding-left: 1rem;

      .ng-input {
        padding-left: 1rem !important;
        padding-right: calc(40px + 1rem) !important;

        input {
          height: 28px;
        }
      }
    }
  }

  &.ng-select-multiple {
    .ng-select-container .ng-value-container .ng-input {
      padding-left: 10px !important;
      padding-right: 0 !important;
      padding-bottom: 6px !important;
    }

    .ng-select-container .ng-value-container .ng-placeholder {
      top: initial;
      padding-left: 10px;
    }
  }

  &.ng-select-opened,
  &.ng-select-focused {
    .ng-select-container {
      background-color: var(--color-background-2);
    }
  }

  .ng-arrow-wrapper {
    padding-right: 0.8rem;
    width: 32px;
  }

  .ng-arrow {
    transition: var(--transition-slow);
    border-color: var(--color-icon-input) transparent transparent !important;
  }

  .ng-clear-wrapper {
    color: var(--color-icon-input);

    &:hover .ng-clear {
      color: var(--color-warn);
      transition: var(--transition);
    }
  }

  &.ng-select-opened > .ng-select-container .ng-arrow {
    border-width: 5px 5px 2.5px;
    transform: rotateX(180deg);
  }

  // Custom class for multiple styling
  .ng-select-multiple-label {
    display: flex;
    background-color: var(--color-background-accent);
    color: white;
    border-radius: 20px;
    margin-top: -4px;

    .number {
      padding: 6px 3px 6px 10px;
      font-weight: 500;
    }

    .clear {
      height: 24px;
      width: 24px;
      margin-top: 2px;
      margin-right: 2px;
      border-radius: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      cursor: pointer;
      transition: var(--transition);
      padding-bottom: 1px;

      &:hover {
        background-color: var(--color-background-accent-hover);
      }
    }
  }

  .image-option {
    display: flex;
    flex-direction: row;
    align-items: center;

    app-avatar {
      margin-right: 0.5rem;
    }
  }
}

.ng-dropdown-panel {
  box-shadow: var(--shadow-menu);

  .ng-option {
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex !important;
    align-items: center;
    min-height: var(--height-input);
    padding: 0 16px;
    text-align: left;
    text-decoration: none;
    cursor: pointer;
    outline: 0;
    -webkit-tap-highlight-color: transparent;
    transition: var(--transition);

    &:last-child {
      border-radius: 0 0 var(--border-radius) var(--border-radius);
    }

    .image-option {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: -4px 0;

      app-avatar {
        margin-right: 0.5rem;
      }
    }
  }
}

// Emoji mart

.emoji-mart-search input {
  border: 1px solid transparent;
}

.emoji-mart-search-icon {
  top: 11px;
}

.emoji-mart-scroll {
  scrollbar-width: thin;

  &::-webkit-scrollbar-track {
    background-color: var(--color-background-2);
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: var(--color-background-2);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #959595;
    border-radius: 10px;
  }
}

// ///////////////////////////////////////////////////////////////

.card {
  padding: 1.5rem;
  box-shadow: var(--shadow);
  // border: 2px solid var(--color-background-2);
  border-radius: 8px;

  @include media('<tablet') {
    padding: 0.5rem;
  }
}

.form-group {
  display: flex;
  flex-direction: column;
  position: relative;

  p.prefix,
  p.suffix {
    font-weight: 600;
  }

  .prefix {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 12px;
    color: var(--color-icon-input);
    margin: 0;

    & ~ input {
      padding-left: 48px;
    }
  }

  .suffix {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 12px;
    color: var(--color-icon-input);
    margin: 0;

    & ~ input {
      padding-right: 48px;
    }

    &.clear-icon {
      font-size: 15px;
      cursor: pointer;
      transition: var(--transition);

      &:hover {
        color: var(--color-warn);
      }
    }
  }

  button.suffix {
    height: 32px;
    width: 32px;
    line-height: 32px;

    i {
      font-size: 20px;
    }
  }

  label {
    margin-bottom: 0.3rem;
    font-size: 12px;
    font-weight: 600;
  }
}

.page-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2rem;

  @include media('<tablet') {
    justify-content: flex-start;
    flex-wrap: wrap;

    .header-tools {
      margin-top: 0.7rem;
      width: 100%;
    }
  }

  app-back {
    margin-right: 1rem;
  }

  h1 {
    margin: 0;
    flex-grow: 1;
  }

  .header-tools {
    display: flex;

    > *:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
}

.table-wrapper {
  width: 100%;

  .table-selection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    background-color: var(--color-primary-active);
    color: var(--color-primary-text);
    border-radius: 8px 8px 0 0;
    height: 56px;
    max-height: 0;
    overflow: hidden;
    transition: var(--transition-slow);

    .actions {
      > *:not(:last-child) {
        margin-right: 0.5rem;
      }
    }

    &.visible {
      max-height: 56px;

      & + .table-container .mat-table thead th {
        &:first-child {
          border-radius: 0 0 0 8px;
        }

        &:last-child {
          border-radius: 0 0 8px 0;
        }
      }
    }
  }

  .table-container {
    width: 100%;
    overflow: auto;
    min-height: 100px;
  }
}

.snackbar {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  padding: 8px 12px;
  border-radius: 8px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
  min-width: 89px;
  min-height: 36px;
  width: auto;

  .mat-simple-snackbar-action {
    button {
      height: 40px;
      width: 40px;
      padding: 0;

      .mat-button-wrapper {
        color: white;
        font-size: 18px;
      }
    }
  }
}

.success-snackbar {
  background-color: var(--color-success);
  color: white;
}

.error-snackbar {
  background-color: var(--color-warn);
  color: white;
}
