// Variables
// --------------------------

$icons8-css-prefix: 'icons8';
$icons8-font-name: 'My Dialogues - Icons';
$icons8-font-path: '../fonts';

$icons8-var-arrow-long: '\f101';
$icons8-var-arrow-small: '\f102';
$icons8-var-calendar: '\f103';
$icons8-var-card: '\f104';
$icons8-var-chat-bubble: '\f105';
$icons8-var-check-mark---square: '\f106';
$icons8-var-check-mark: '\f107';
$icons8-var-check-circle-fill: '\f108';
$icons8-var-circle-selected: '\f109';
$icons8-var-clock-timer: '\f10b';
$icons8-var-close---circle: '\f10c';
$icons8-var-close: '\f10d';
$icons8-var-dashboard: '\f10f';
$icons8-var-dialogue: '\f110';
$icons8-var-dots: '\f111';
$icons8-var-duplicate: '\f112';
$icons8-var-edit: '\f113';
$icons8-var-email: '\f114';
$icons8-var-eye: '\f115';
$icons8-var-file: '\f116';
$icons8-var-graph: '\f117';
$icons8-var-group: '\f118';
$icons8-var-image: '\f119';
$icons8-var-info: '\f11a';
$icons8-var-lock: '\f11b';
$icons8-var-megaphone: '\f11c';
$icons8-var-minus: '\f11d';
$icons8-var-money: '\f11e';
$icons8-var-move---dots: '\f11f';
$icons8-var-multiple-choice: '\f120';
$icons8-var-notifications: '\f121';
$icons8-var-number: '\f122';
$icons8-var-numeric: '\f123';
$icons8-var-pause: '\f124';
$icons8-var-phone: '\f125';
$icons8-var-play: '\f127';
$icons8-var-plus: '\f128';
$icons8-var-privacy-policy: '\f129';
$icons8-var-question-circle: '\f12a';
$icons8-var-search: '\f12c';
$icons8-var-settings-cog: '\f12e';
$icons8-var-single-choice: '\f12f';
$icons8-var-slider: '\f130';
$icons8-var-support: '\f133';
$icons8-var-terms-and-conditions: '\f134';
$icons8-var-thunder-stroke: '\f136';
$icons8-var-thunder: '\f137';
$icons8-var-trash: '\f138';
$icons8-var-user-stroke: '\f139';
$icons8-var-user: '\f13a';
$icons8-var-wallet-fill: '\f13b';
$icons8-var-zoom-in: '\f13c';
$icons8-var-circle: '\f13d';
$icons8-var-square: '\f13e';
$icons8-var-cog-stoke: '\f13f';
$icons8-var-send: '\f140';
$icons8-var-imagechoice: '\f141';
$icons8-var-openended: '\f142';
$icons8-var-reorderitems: '\f143';
$icons8-var-starrating: '\f144';
